.card {
  width: calc(100% - 2 * 16px);
  max-width: calc(272px - 2 * 16px);
  /*max-height: calc(225px - 13px - 17px);*/
  height: auto;
  padding: 17px 16px 13px 16px;
  border-radius: 10px;
  font-family: Montserrat;
  margin-bottom: 12px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  transition-duration: 0.2s;
  cursor: pointer;
}

.card:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.location {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}

.date {
  font-weight: 300;
  font-size: 16px;
}
.text {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.icon {
  height: 24px;
  width: 20px;
  margin-right: 12px;
}
