.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10000;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.column {
  position: sticky;
  width: 120px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #000;
}

.closeBtn {
  /*height: 25px;
	width: 25px;*/
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-top: 35px;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.closeBtn img {
  height: 24px;
  width: 24px;
  margin: 0;
}

.viewer {
  position: sticky;
  flex-shrink: 1;
  width: calc(100vw - 2 * 120px) !important;
  height: 100vh !important;

  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.viewer img {
  max-width: 100%;
  max-height: 100%;
}

.viewer video {
  max-height: 100%;
  max-width: 100%;
}

.arrow {
  height: 100px;
  padding: 0;
  width: auto;
  border: none;
  position: absolute;
  top: calc((100vh - 150px) / 2);
  background: none;
}

.arrow img {
  height: 100px;
}

.delete {
  position: absolute;
  top: calc(100vh - 34px - 35px);
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: none;
  cursor: pointer;
  padding: 5px;
  filter: invert(1);
}

.delete img {
  height: 24px;
  width: 24px;
  margin: 0;
}

.deleteValidation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(58, 58, 58, 0.3);
}

.deleteCard {
  border-radius: 10px;
  background-color: #fcfcfc;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  padding: 22px 15px;
  font-family: Montserrat;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}

.deleteBtnWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.deleteYes {
  padding: 5px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fe006e;
  border-radius: 50px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.deleteNo {
  font-family: Montserrat;
  color: #383737;
  font-size: 20px;
  font-weight: normal;
  background: none;
  border: none;
}
