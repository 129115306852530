.wrapper {
}

.columnWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: calc(50% - 6px);
}

.left {
  margin-right: 12px;
}

.right {
}

.date {
  font-family: Montserrat;
  font-size: 24px;
  color: black;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 16px;
}
