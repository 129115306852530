body {
  margin: 0;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.bigPink {
  width: 212px;
  height: 44px;
  border: none;
  background-color: #fe006e;
  border-radius: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'MontSerrat';
  font-size: 16px;
  margin-top: 40px;
}

.smallPink {
  width: 106px;
  height: 36px;
  background-color: #fe006e;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  color: white;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  font-family: 'MontSerrat';
  border: none;
}

.smallText {
  font-family: 'MontSerrat';
  font-size: 13px;
  color: black;
  font-weight: 400;
  text-decoration: none;
}

.smallText:hover {
  text-decoration: underline;
}

.title {
  font-family: 'Coolvetica';
  font-size: 50px;
  font-weight: 300;
  margin-top: 245px;
}

.paddingLeft {
  padding-left: 118px;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  border-radius: 2px;
  border: 1px solid black;
  width: 1em;
  height: 1em;
  margin: 0;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 15px 5px #f3f3f3;
}

input[type='checkbox']:focus {
  outline: none;
}

input[type='checkbox']:checked {
  background: #d3d3d3;
  margin-right: 10px;
  position: relative;
}

input[type='checkbox']:checked:before {
  content: 'x';
}

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  flex-grow: 5;
  background-color: rgba(56, 55, 55, 0.7);
}
