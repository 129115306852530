@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form {
  width: 100%;
}

.Auth_mainWrapper__19uJF {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.Auth_wrapper__3lvXh {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.Auth_inputWrapper__6Khwc {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*width: 100%;*/
  margin-top: 20px;
}

.Auth_input__1hiaQ {
  margin-bottom: 20px;
  width: 412px;
  height: 30px;
  font-size: 18px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: black;
  border-width: 0.25px;
  font-family: Montserrat, sans-serif;
  max-width: 100%;
}

.Auth_input__1hiaQ::-webkit-input-placeholder {
  color: black;
}

.Auth_input__1hiaQ::-moz-placeholder {
  color: black;
}

.Auth_input__1hiaQ:-ms-input-placeholder {
  color: black;
}

.Auth_input__1hiaQ::-ms-input-placeholder {
  color: black;
}

.Auth_input__1hiaQ::placeholder {
  color: black;
}

.Auth_input__1hiaQ:focus {
  border: 0.25px solid black;
  outline: none;
}

.Auth_span__3Bdp_ {
  font-family: 'Coolvetica';
  font-size: 26px;
}

.Auth_warning__2-ZFK {
  text-decoration: none;
  color: black;
  text-align: center;
  margin-top: 40px;
}

.Auth_conditions__2-Wbx {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.Auth_conditions__2-Wbx:hover {
  text-decoration: underline;
}

.Auth_subbutton__1jUar {
  color: black;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  margin-top: 19.9px;
}

.Auth_underInput__Z8pZi {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.Auth_pinkText__3-uwS {
  color: #fd096d;
}

.Auth_subbutton__1jUar:hover {
  text-decoration: underline;
}

.Auth_title__2Cp-P {
  font-family: 'Coolvetica';
  font-size: 64px;
  font-weight: 300;
}

.Auth_iconBtn__2Bup8 {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  border: none;
  padding: 5px;
  border-radius: 50px;
  margin: 10px;
}

.Auth_iconWrapper__1PNr9 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

body {
  margin: 0;
}

.main_flexColumn__2QzKE {
  display: flex;
  flex-direction: column;
}

.main_bigPink__1Te1Z {
  width: 212px;
  height: 44px;
  border: none;
  background-color: #fe006e;
  border-radius: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'MontSerrat';
  font-size: 16px;
  margin-top: 40px;
}

.main_smallPink__3TOLp {
  width: 106px;
  height: 36px;
  background-color: #fe006e;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  color: white;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  font-family: 'MontSerrat';
  border: none;
}

.main_smallText__NOwxN {
  font-family: 'MontSerrat';
  font-size: 13px;
  color: black;
  font-weight: 400;
  text-decoration: none;
}

.main_smallText__NOwxN:hover {
  text-decoration: underline;
}

.main_title__3OJuJ {
  font-family: 'Coolvetica';
  font-size: 50px;
  font-weight: 300;
  margin-top: 245px;
}

.main_paddingLeft__34XRw {
  padding-left: 118px;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  border-radius: 2px;
  border: 1px solid black;
  width: 1em;
  height: 1em;
  margin: 0;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 15px 5px #f3f3f3;
}

input[type='checkbox']:focus {
  outline: none;
}

input[type='checkbox']:checked {
  background: #d3d3d3;
  margin-right: 10px;
  position: relative;
}

input[type='checkbox']:checked:before {
  content: 'x';
}

.main_modalWrapper__1JmkW {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  flex-grow: 5;
  background-color: rgba(56, 55, 55, 0.7);
}

.Frame_wrapper__1DPAn {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.Frame_divider__1e-CH {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Frame_left__1zWKu,
.Frame_right__399qp {
  flex-grow: 1;
  height: 100%;
}

.Frame_left__1zWKu {
  width: 45%;
  padding: 48px;
}

.Frame_right__399qp {
  width: 55%;
}

@media (max-width: 414px) {
  .Frame_right__399qp {
    display: none;
  }
}

.Frame_illu__1JOt- {
  height: 100vh;
  width: 100%;
}

.Navbar_nav__2tMgW {
  height: 64px;
  width: calc(100vw - 100px);
  padding: 0 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50000;
}

.Navbar_left__gHX4O,
.Navbar_right__2e31N {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Navbar_left__gHX4O a {
  margin: 0 20px;
  padding: 10px;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.Navbar_logo__2ItZz {
  height: 40px;
  widows: 40px;
  margin-right: 30px;
}

.Navbar_imgWrapper__1MPII {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  overflow: hidden;
  border: 2px solid rgba(100, 100, 100, 0.4);
}

.Navbar_profilepic__3kMvs {
  max-width: 100%;
  min-height: 100%;
}

.Navbar_dark__3On-G .Navbar_left__gHX4O a {
  color: black !important;
}

.Navbar_light__3Rvln .Navbar_left__gHX4O a {
  color: white !important;
}

.TripCard_card__3e0uS {
  height: 322px;
  width: 360px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fcfcfc;
  overflow: hidden;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  margin-right: 60px;
  margin-bottom: 60px;
  border: none;
  padding: 0;
  text-align: unset;
  cursor: pointer;
  color: #383737;
  text-decoration: none;
}

.TripCard_imageWrapper__1TvQT {
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #9ad3fc;
}

.TripCard_imageWrapper__1TvQT img {
  max-width: 100%;
}

.TripCard_noThumb__3mPtc {
  max-width: unset !important;
  max-height: 100%;
}

.TripCard_textWrapper__1fVvb {
  display: flex;
  flex-direction: column;
  padding: 11px 24px 24px 14px;
  font-family: Montserrat;
}

.TripCard_title__1ANW3 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.TripCard_infoWrapper__2Bb18 {
  display: flex;
  flex-direction: column;
}

.TripCard_numberBold__3mDmh {
  font-weight: 700;
  width: 40px;
}

.PickTrip_wrapper__1U8UL {
  width: 100vw;
  /*height: 100vh;*/
  overflow: hidden;
}

.PickTrip_background___KQ-L {
  width: 100vw;
  /*height: 100vh;*/
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: hidden;
  z-index: -1000;
}

.PickTrip_cardWrapper__1X0Fe {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100vw - 2 * 118px);
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 118px;
}

.LogoutBtn_btn__3VLtH {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  border: none;
  padding: 0;
  border: none;
  height: 30px;
  width: 150px;
  border-radius: 40px;
  color: #fcfcfc;
  background-color: #818181;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
}

.ProfilePic_wrapper__3mcoD {
  height: 319px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.ProfilePic_picWrapper__1BarQ {
  height: 134px;
  width: 134px;
  display: flex;
  align-self: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 100px;
}

.ProfilePic_pic__2jGY6 {
  width: 100%;
}

.ProfilePic_iconWrapper__2VxCg {
  height: 32px;
  width: 32px;
  background-color: #fe006e;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProfilePic_iconWrapper__2VxCg img {
  height: 22px;
}

.ProfilePic_editPic__2aUBi {
  position: relative;
  left: 50px;
  top: -40px;
  cursor: pointer;
}

.ProfilePic_editPic__2aUBi input {
  display: none;
}

.Profile_wrapper__UZ6ri {
}

.Profile_background__IUH75 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: #fcfcfc;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

.Profile_foreground__8GP70 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.Profile_card__3kYOA {
  width: calc(456px - 2 * 53px);
  padding: 20px 53px;
  background-color: #fefefe;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Profile_title__dIPgy {
  font-family: Coolvetica;
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0px;
  align-self: flex-start;
  margin-top: 0;
  margin-bottom: 7px;
}

.Profile_input__2Khat {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Montserrat;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  color: #383737;
  margin-top: 11px;
}

.Profile_input__2Khat input {
  width: calc(349px - 2 * 5px);
  height: calc(35px - 2 * 3px);
  padding: 3px 5px;
  border-radius: 5px;
  background-color: #fefefe;
  box-shadow: inset 0px 1px 4px rgba(117, 117, 117, 0.25);
  border: none;
  color: black;
  font-family: Montserrat;
  font-weight: 500;
}

.Profile_input__2Khat textarea {
  width: calc(349px - 2 * 5px);
  height: calc(112px - 2 * 3px);
  padding: 3px 5px;
  border-radius: 5px;
  background-color: #fefefe;
  box-shadow: inset 0px 1px 4px rgba(117, 117, 117, 0.25);
  border: none;
  margin-bottom: 10px;
  resize: none;
  font-family: Montserrat;
  font-weight: 500;
}

.Profile_toggle__KYd1D {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
}

.Profile_middleToggle__1HBBR {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.Profile_profilePicWrapper__1SwAv {
  height: 319px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.Profile_profileWrapper__2EiyF {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.Profile_info___Brb1 {
  margin-right: 40px;
  margin-bottom: 19px;
}

.Profile_password__2v0KV {
  margin-top: 40px;
}

.Profile_submit__5XCQP {
  align-self: flex-end;
  margin-top: 19px;
  margin-bottom: 10px;
}

.Mapbox_map__21HQg {
  height: 100%;
  width: 100%;
}

.LoadingPage_wrapper__1LDMw {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DayBox_wrapper__2k6J7 {
}

.DayBox_columnWrapper__DWyPn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.DayBox_column__37egV {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: calc(50% - 6px);
}

.DayBox_left__1u-i1 {
  margin-right: 12px;
}

.DayBox_right__2YgI3 {
}

.DayBox_date__13hCB {
  font-family: Montserrat;
  font-size: 24px;
  color: black;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 16px;
}

.MediaCard_card__2Ybxz {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  max-width: 272px;
  min-width: 100%;
  margin-bottom: 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  cursor: pointer;
}

.MediaCard_card__2Ybxz:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.MediaCard_portrait__2CwL0 {
  height: 364px;
  width: 100%;
}

.MediaCard_landscape__1Fnyt {
  max-height: 225px;
  min-height: 100px;
  height: 400px;
}

/*.card img {
	max-width: 100%;
}

.card video {
	max-width: 100%;
}
*/

.MediaCard_portrait__2CwL0 img,
.MediaCard_portait__Lf4ku video {
  max-height: 100%;
  /*min-width: 100%;*/
  /*z-index: 1;*/
}

.MediaCard_landscape__1Fnyt img,
.MediaCard_landscape__1Fnyt video {
  max-width: 100%;
  /*z-index: 1;*/
}

.MediaCard_videoWrapper__3BN7S {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  top: 0;
  flex-direction: row;
  align-self: center;
  justify-content: center;
}

.MediaCard_playBtnWrapper__33Y4u {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: relative;
  align-self: center;
  left: 0;
}

.MediaCard_videoWrapper__3BN7S video {
  /*	position: relative;
	top: -200%;*/
  position: absolute;
  max-width: 100%;
}

.MediaCard_playBtn__3WNFX {
  /*position: relative;*/
  /*flex-grow: 1;*/
  align-self: center;
  left: calc((100% - 32px) / 2);
  z-index: 10;
  height: 64px;
  width: 64px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 30px;
  background-color: rgba(254, 254, 254, 0.3);
}

.MediaCard_playBtn__3WNFX img {
  height: 44px;
  padding-left: 2px;
  fill: white;
}

.Details_wrapper__3r49H {
  width: 752px;
  background: #fefefe;
  border-radius: 10px;
  z-index: 10000;
  color: #383737;
}

.Details_topBorder__18KWx {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.Details_closeBtn__284tI {
  /*height: 25px;
	width: 25px;*/
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 0 0 0 16px;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.Details_closeBtn__284tI img {
  height: 20px;
  width: 20px;
  margin: 0;
}

.Details_inverted__1Kv-0 {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.Details_deleteConfirmation__18Cmq {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  margin: 0 88px 0 58px;
  color: white;
}

.Details_deleteConfirmation__18Cmq span {
  font-weight: 600;
  font-size: 16px;
}

.Details_stopDelete__zijj8 {
  font-weight: 500;
}

.Details_confirmDelete__5bnip {
  font-weight: 800;
}

.Details_deleteConfirmation__18Cmq button {
  font-size: 16px;
  font-family: Montserrat;
  padding: 0;
  line-height: 20px;
  border: none;
  cursor: pointer;
  color: white;
  background: none;
}

.Details_colorPicker__3FJmY {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  margin-right: 40px;
}

.Details_colorBtn__2ye1X {
  margin-left: 24px;
  height: 24px;
  width: 24px;
  padding: 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  /*padding: 2px;*/

  /*display: flex;
	justify-content: center;
	align-items: center;*/
  background: none;
  border-radius: 40px;
  border: none;
  cursor: pointer;
}

.Details_colorBtn__2ye1X:hover {
  padding: 2px;
  border: 1px solid black;
}

.Details_colorBtn__2ye1X div {
  height: 100% !important;
  width: 100%;
  border-radius: 40px;
}

.Details_selectedColor__2LzxN {
  padding: 2px;
  border: 1px solid black;
}

.Details_actionWrapper__B7tGI {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 77px;
  margin-right: 40px;
}

.Details_actionWrapper__B7tGI button {
  margin-left: 29px;
  padding: 0;
  background: none;
  border: none;
}

.Details_actionWrapper__B7tGI img {
  height: 20px;
}

.Details_textWrapper__2prEB {
  width: auto;
  margin: 0 40px 10px 40px;
  position: relative;
  font-family: Montserrat;
  padding: 19px 16px 12px 16px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.Details_background__NOYuQ {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.Details_textWrapper__2prEB p,
.Details_textWrapper__2prEB textarea,
.Details_textWrapper__2prEB h4,
.Details_textWrapper__2prEB span {
  z-index: 10;
  position: relative;
}

.Details_location__1O_Vq {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 7px 0;
}

.Details_markerIcon__1ExEu {
  margin-right: 12px;
}

.Details_date__1LKG8 {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}

.Details_text__wgKKr {
  font-weight: normal;
  font-style: normal;
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 6px;
  margin-top: 9px;
}

.Details_editText__1V1f5 {
  font-weight: normal;
  font-style: normal;
  font-family: Montserrat;
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 6px;
  margin-top: 9px;
  border-radius: 10px;
  background: none;
  padding: 1px;
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.Details_mapWrapper__3izYD {
  width: auto;
  margin: 0 40px 18px 40px;
  height: 138px;
  border-radius: 10px;
  overflow: hidden;
}

.Details_validateWrapper__2u8nt {
  width: auto;
  margin: 0 40px 12px 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.Details_inverted__1Kv-0 {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.TextCard_card__2G5hz {
  width: calc(100% - 2 * 16px);
  max-width: calc(272px - 2 * 16px);
  /*max-height: calc(225px - 13px - 17px);*/
  height: auto;
  padding: 17px 16px 13px 16px;
  border-radius: 10px;
  font-family: Montserrat;
  margin-bottom: 12px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  cursor: pointer;
}

.TextCard_card__2G5hz:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.TextCard_location__2zLPG {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}

.TextCard_date__Dijb7 {
  font-weight: 300;
  font-size: 16px;
}
.TextCard_text__2amK9 {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.TextCard_icon__2GKPm {
  height: 24px;
  width: 20px;
  margin-right: 12px;
}

.AudioCard_card__3S23U {
  /*height: calc(122px - 17px - 16px);*/
  padding: 17px 16px 16px 16px;
  width: calc(272px - 2 * 16px);
  max-width: calc(100% - 2 * 16px);
  /*min-width: calc(272px - 2 * 16px);
	max-width: calc(272px - 2 * 16px);*/
  background-color: #ff894e;
  border-radius: 10px;
  margin-bottom: 12px;
  font-family: Montserrat;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
}

.AudioCard_card__3S23U:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.AudioCard_playWrapper__G5UdW {
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  width: 130px;
}

.AudioCard_playBtn__1agwF {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 30px;
  background-color: rgba(254, 254, 254, 0.3);
}

.AudioCard_playBtn__1agwF img {
  height: 22px;
  fill: white;
}

.AudioCard_playBtnPause__2yjiT {
  padding-left: 2px;
}

.AudioCard_playTime__lfA6c {
  font-size: 24px;
  font-weight: 400;
  /*letter-spacing: 0px;*/
}

.AudioCard_location__2VizN {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}

.AudioCard_date__RFd11 {
  font-weight: 300;
  font-size: 16px;
}

.MediaViewer_wrapper__3HrzI {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10000;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.MediaViewer_column__3hgYq {
  position: -webkit-sticky;
  position: sticky;
  width: 120px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #000;
}

.MediaViewer_closeBtn__3OoH2 {
  /*height: 25px;
	width: 25px;*/
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-top: 35px;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.MediaViewer_closeBtn__3OoH2 img {
  height: 24px;
  width: 24px;
  margin: 0;
}

.MediaViewer_viewer__25Yy1 {
  position: -webkit-sticky;
  position: sticky;
  flex-shrink: 1;
  width: calc(100vw - 2 * 120px) !important;
  height: 100vh !important;

  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.MediaViewer_viewer__25Yy1 img {
  max-width: 100%;
  max-height: 100%;
}

.MediaViewer_viewer__25Yy1 video {
  max-height: 100%;
  max-width: 100%;
}

.MediaViewer_arrow__1iShk {
  height: 100px;
  padding: 0;
  width: auto;
  border: none;
  position: absolute;
  top: calc((100vh - 150px) / 2);
  background: none;
}

.MediaViewer_arrow__1iShk img {
  height: 100px;
}

.MediaViewer_delete__1w37a {
  position: absolute;
  top: calc(100vh - 34px - 35px);
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: none;
  cursor: pointer;
  padding: 5px;
  -webkit-filter: invert(1);
          filter: invert(1);
}

.MediaViewer_delete__1w37a img {
  height: 24px;
  width: 24px;
  margin: 0;
}

.MediaViewer_deleteValidation__35TEJ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(58, 58, 58, 0.3);
}

.MediaViewer_deleteCard__2YJ0T {
  border-radius: 10px;
  background-color: #fcfcfc;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  padding: 22px 15px;
  font-family: Montserrat;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}

.MediaViewer_deleteBtnWrapper__2N5qw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.MediaViewer_deleteYes__19XzG {
  padding: 5px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fe006e;
  border-radius: 50px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.MediaViewer_deleteNo__1qqTr {
  font-family: Montserrat;
  color: #383737;
  font-size: 20px;
  font-weight: normal;
  background: none;
  border: none;
}

.TripDetails_wrapper__3ZPQf {
  height: 100vh;
  width: 100vw;
  /*display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;*/
  /*overflow-x: hidden;*/
  /*overflow: hidden;*/
}

.TripDetails_banner__2YGbP {
  width: calc(100% - 2 * 56px);
  height: calc(264px - 2 * 48px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 48px 53px;
}

.TripDetails_imgWrapper__3Tu52 {
  display: flex;
  position: absolute;
  height: 264px;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: -1000;
}

.TripDetails_imgWrapper__3Tu52 img {
  max-width: 100%;
  -webkit-filter: brightness(0.7);
          filter: brightness(0.7);
}

.TripDetails_tripTitle__f85HN {
  font-family: Coolvetica;
  font-size: 40px;
  font-weight: 600;
  margin: 13px 0;
  color: white;
}

.TripDetails_tripDate__2FNz6 {
  color: white;
  font-family: Montserrat;
  font-size: 18px;
}

.TripDetails_menuWrapper__1Hbyt {
  width: 100vw;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: -27px;
}

.TripDetails_menuPill__2zGVJ {
  width: 668px;
  height: 54px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.TripDetails_menuPill__2zGVJ button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-size: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.TripDetails_menuPill__2zGVJ img {
  height: 15px;
  margin-right: 5px;
}

.TripDetails_searchIcon__2n3BT {
  height: 22px !important;
  width: 22px !important;
  margin: 0 !important;
}

.TripDetails_printBtn__3OlkB {
  width: 106px;
  height: 36px;
  background-color: #fe006e;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 30px;
  margin-right: 48px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.TripDetails_pseudoLeft__3nSCt {
  width: 154px;
}

.TripDetails_scrollWrapper__1bxnZ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100vw;
  margin-top: 20px;
  /*height: 740px;*/
}

.TripDetails_mapWrapper__HZFPF {
  width: 45%;
  height: 100vh;
  overflow: hidden;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.TripDetails_timelineWrapper__1Oihz {
  width: 45%;
  /*height: 100%;*/
  /*overflow: scroll;*/
}

.ComingSoon_wrapper__3IY_I {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.ComingSoon_backdrop__1KnSS {
	height: 100vh;
	position: absolute;
	top: 0;
	right: 0;

}

.ComingSoon_title__3ieMl {
	font-family: Coolvetica;
	font-size: 60px;
	color: black;
	font-weight: normal;
	position: relative;
}
@font-face {
  font-family: Coolvetica;
  src: url('/coolvetica_rg.ttf');
}

.active {
  font-weight: 700 !important;
}

button {
  cursor: pointer;
}

.white {
  background-color: #fefefe;
}

.yellow {
  background-color: #ffd84e;
}

.red {
  background-color: #f96157;
}

.blue {
  background-color: #9ad3fc;
}

.purple {
  background-color: #d790f9;
}

.cyan {
  background-color: #69e7ce;
}

.pink {
  background-color: #ff8caf;
}

.orange {
  background-color: #ff984e;
}

.green {
  background-color: #77e16e;
}

.lavender {
  background-color: #bbb2e1;
}

.rc-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 46px;
  height: 28px;
  line-height: 20px;
  padding: 0;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: 1px solid #ccc;
  background-color: #ccc;
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-switch-inner {
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 24px;
  top: 0;
}
.rc-switch:after {
  position: absolute;
  width: 22px;
  height: 22px;
  left: 2px;
  top: 2px;
  border-radius: 50% 50%;
  background-color: #fff;
  content: ' ';
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: rcSwitchOff;
  animation-name: rcSwitchOff;
}
.rc-switch:hover:after {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-animation-name: rcSwitchOn;
  animation-name: rcSwitchOn;
}
.rc-switch:focus {
  box-shadow: 0 0 0 2px #d5f1fd;
  outline: none;
}
.rc-switch-checked {
  border: 1px solid #d790f9;
  background-color: #d790f9;
}
.rc-switch-checked .rc-switch-inner {
  left: 6px;
}
.rc-switch-checked:after {
  left: 20px;
}
.rc-switch-disabled {
  cursor: no-drop;
  background: #ccc;
  border-color: #ccc;
}
.rc-switch-disabled:after {
  background: #9e9e9e;
  -webkit-animation-name: none;
  animation-name: none;
  cursor: no-drop;
}
.rc-switch-disabled:hover:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation-name: none;
  animation-name: none;
}
.rc-switch-label {
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
@-webkit-keyframes rcSwitchOn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes rcSwitchOn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@-webkit-keyframes rcSwitchOff {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes rcSwitchOff {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fe006e;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fe006e transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

