.wrapper {
  height: 319px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.picWrapper {
  height: 134px;
  width: 134px;
  display: flex;
  align-self: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 100px;
}

.pic {
  width: 100%;
}

.iconWrapper {
  height: 32px;
  width: 32px;
  background-color: #fe006e;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconWrapper img {
  height: 22px;
}

.editPic {
  position: relative;
  left: 50px;
  top: -40px;
  cursor: pointer;
}

.editPic input {
  display: none;
}
