.mainWrapper {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*width: 100%;*/
  margin-top: 20px;
}

.input {
  margin-bottom: 20px;
  width: 412px;
  height: 30px;
  font-size: 18px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: black;
  border-width: 0.25px;
  font-family: Montserrat, sans-serif;
  max-width: 100%;
}

.input::placeholder {
  color: black;
}

.input:focus {
  border: 0.25px solid black;
  outline: none;
}

.span {
  font-family: 'Coolvetica';
  font-size: 26px;
}

.warning {
  text-decoration: none;
  color: black;
  text-align: center;
  margin-top: 40px;
}

.conditions {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.conditions:hover {
  text-decoration: underline;
}

.subbutton {
  color: black;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  margin-top: 19.9px;
}

.underInput {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.pinkText {
  color: #fd096d;
}

.subbutton:hover {
  text-decoration: underline;
}

.title {
  font-family: 'Coolvetica';
  font-size: 64px;
  font-weight: 300;
}

.iconBtn {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  border: none;
  padding: 5px;
  border-radius: 50px;
  margin: 10px;
}

.iconWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
