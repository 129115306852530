.card {
  /*height: calc(122px - 17px - 16px);*/
  padding: 17px 16px 16px 16px;
  width: calc(272px - 2 * 16px);
  max-width: calc(100% - 2 * 16px);
  /*min-width: calc(272px - 2 * 16px);
	max-width: calc(272px - 2 * 16px);*/
  background-color: #ff894e;
  border-radius: 10px;
  margin-bottom: 12px;
  font-family: Montserrat;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
}

.card:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.playWrapper {
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  width: 130px;
}

.playBtn {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 30px;
  background-color: rgba(254, 254, 254, 0.3);
}

.playBtn img {
  height: 22px;
  fill: white;
}

.playBtnPause {
  padding-left: 2px;
}

.playTime {
  font-size: 24px;
  font-weight: 400;
  /*letter-spacing: 0px;*/
}

.location {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}

.date {
  font-weight: 300;
  font-size: 16px;
}
