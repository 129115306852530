.white {
  background-color: #fefefe;
}

.yellow {
  background-color: #ffd84e;
}

.red {
  background-color: #f96157;
}

.blue {
  background-color: #9ad3fc;
}

.purple {
  background-color: #d790f9;
}

.cyan {
  background-color: #69e7ce;
}

.pink {
  background-color: #ff8caf;
}

.orange {
  background-color: #ff984e;
}

.green {
  background-color: #77e16e;
}

.lavender {
  background-color: #bbb2e1;
}
