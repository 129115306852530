.nav {
  height: 64px;
  width: calc(100vw - 100px);
  padding: 0 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50000;
}

.left,
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.left a {
  margin: 0 20px;
  padding: 10px;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.logo {
  height: 40px;
  widows: 40px;
  margin-right: 30px;
}

.imgWrapper {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  overflow: hidden;
  border: 2px solid rgba(100, 100, 100, 0.4);
}

.profilepic {
  max-width: 100%;
  min-height: 100%;
}

.dark .left a {
  color: black !important;
}

.light .left a {
  color: white !important;
}
