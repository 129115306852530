.wrapper {
  height: 100vh;
  width: 100vw;
  /*display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;*/
  /*overflow-x: hidden;*/
  /*overflow: hidden;*/
}

.banner {
  width: calc(100% - 2 * 56px);
  height: calc(264px - 2 * 48px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 48px 53px;
}

.imgWrapper {
  display: flex;
  position: absolute;
  height: 264px;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: -1000;
}

.imgWrapper img {
  max-width: 100%;
  filter: brightness(0.7);
}

.tripTitle {
  font-family: Coolvetica;
  font-size: 40px;
  font-weight: 600;
  margin: 13px 0;
  color: white;
}

.tripDate {
  color: white;
  font-family: Montserrat;
  font-size: 18px;
}

.menuWrapper {
  width: 100vw;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: -27px;
}

.menuPill {
  width: 668px;
  height: 54px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.menuPill button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-size: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.menuPill img {
  height: 15px;
  margin-right: 5px;
}

.searchIcon {
  height: 22px !important;
  width: 22px !important;
  margin: 0 !important;
}

.printBtn {
  width: 106px;
  height: 36px;
  background-color: #fe006e;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 30px;
  margin-right: 48px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.pseudoLeft {
  width: 154px;
}

.scrollWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100vw;
  margin-top: 20px;
  /*height: 740px;*/
}

.mapWrapper {
  width: 45%;
  height: 100vh;
  overflow: hidden;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: sticky;
  top: 0;
}

.timelineWrapper {
  width: 45%;
  /*height: 100%;*/
  /*overflow: scroll;*/
}
