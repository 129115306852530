.card {
  height: 322px;
  width: 360px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fcfcfc;
  overflow: hidden;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  margin-right: 60px;
  margin-bottom: 60px;
  border: none;
  padding: 0;
  text-align: unset;
  cursor: pointer;
  color: #383737;
  text-decoration: none;
}

.imageWrapper {
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #9ad3fc;
}

.imageWrapper img {
  max-width: 100%;
}

.noThumb {
  max-width: unset !important;
  max-height: 100%;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  padding: 11px 24px 24px 14px;
  font-family: Montserrat;
}

.title {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
}

.numberBold {
  font-weight: 700;
  width: 40px;
}
