.wrapper {
  width: 100vw;
  /*height: 100vh;*/
  overflow: hidden;
}

.background {
  width: 100vw;
  /*height: 100vh;*/
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: hidden;
  z-index: -1000;
}

.cardWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100vw - 2 * 118px);
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 118px;
}
