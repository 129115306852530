.wrapper {
  width: 752px;
  background: #fefefe;
  border-radius: 10px;
  z-index: 10000;
  color: #383737;
}

.topBorder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.closeBtn {
  /*height: 25px;
	width: 25px;*/
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 0 0 0 16px;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.closeBtn img {
  height: 20px;
  width: 20px;
  margin: 0;
}

.inverted {
  filter: invert(1);
}

.deleteConfirmation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  margin: 0 88px 0 58px;
  color: white;
}

.deleteConfirmation span {
  font-weight: 600;
  font-size: 16px;
}

.stopDelete {
  font-weight: 500;
}

.confirmDelete {
  font-weight: 800;
}

.deleteConfirmation button {
  font-size: 16px;
  font-family: Montserrat;
  padding: 0;
  line-height: 20px;
  border: none;
  cursor: pointer;
  color: white;
  background: none;
}

.colorPicker {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  margin-right: 40px;
}

.colorBtn {
  margin-left: 24px;
  height: 24px;
  width: 24px;
  padding: 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  /*padding: 2px;*/

  /*display: flex;
	justify-content: center;
	align-items: center;*/
  background: none;
  border-radius: 40px;
  border: none;
  cursor: pointer;
}

.colorBtn:hover {
  padding: 2px;
  border: 1px solid black;
}

.colorBtn div {
  height: 100% !important;
  width: 100%;
  border-radius: 40px;
}

.selectedColor {
  padding: 2px;
  border: 1px solid black;
}

.actionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 77px;
  margin-right: 40px;
}

.actionWrapper button {
  margin-left: 29px;
  padding: 0;
  background: none;
  border: none;
}

.actionWrapper img {
  height: 20px;
}

.textWrapper {
  width: auto;
  margin: 0 40px 10px 40px;
  position: relative;
  font-family: Montserrat;
  padding: 19px 16px 12px 16px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.background {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.textWrapper p,
.textWrapper textarea,
.textWrapper h4,
.textWrapper span {
  z-index: 10;
  position: relative;
}

.location {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 7px 0;
}

.markerIcon {
  margin-right: 12px;
}

.date {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}

.text {
  font-weight: normal;
  font-style: normal;
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 6px;
  margin-top: 9px;
}

.editText {
  font-weight: normal;
  font-style: normal;
  font-family: Montserrat;
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 6px;
  margin-top: 9px;
  border-radius: 10px;
  background: none;
  padding: 1px;
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.mapWrapper {
  width: auto;
  margin: 0 40px 18px 40px;
  height: 138px;
  border-radius: 10px;
  overflow: hidden;
}

.validateWrapper {
  width: auto;
  margin: 0 40px 12px 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.inverted {
  filter: invert(1);
}
