.wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.divider {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.left,
.right {
  flex-grow: 1;
  height: 100%;
}

.left {
  width: 45%;
  padding: 48px;
}

.right {
  width: 55%;
}

@media (max-width: 414px) {
  .right {
    display: none;
  }
}

.illu {
  height: 100vh;
  width: 100%;
}
