.btn {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  border: none;
  padding: 0;
  border: none;
  height: 30px;
  width: 150px;
  border-radius: 40px;
  color: #fcfcfc;
  background-color: #818181;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
}
