.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.backdrop {
	height: 100vh;
	position: absolute;
	top: 0;
	right: 0;

}

.title {
	font-family: Coolvetica;
	font-size: 60px;
	color: black;
	font-weight: normal;
	position: relative;
}