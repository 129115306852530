.wrapper {
}

.background {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: #fcfcfc;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

.foreground {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.card {
  width: calc(456px - 2 * 53px);
  padding: 20px 53px;
  background-color: #fefefe;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: Coolvetica;
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0px;
  align-self: flex-start;
  margin-top: 0;
  margin-bottom: 7px;
}

.input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Montserrat;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  color: #383737;
  margin-top: 11px;
}

.input input {
  width: calc(349px - 2 * 5px);
  height: calc(35px - 2 * 3px);
  padding: 3px 5px;
  border-radius: 5px;
  background-color: #fefefe;
  box-shadow: inset 0px 1px 4px rgba(117, 117, 117, 0.25);
  border: none;
  color: black;
  font-family: Montserrat;
  font-weight: 500;
}

.input textarea {
  width: calc(349px - 2 * 5px);
  height: calc(112px - 2 * 3px);
  padding: 3px 5px;
  border-radius: 5px;
  background-color: #fefefe;
  box-shadow: inset 0px 1px 4px rgba(117, 117, 117, 0.25);
  border: none;
  margin-bottom: 10px;
  resize: none;
  font-family: Montserrat;
  font-weight: 500;
}

.toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
}

.middleToggle {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.profilePicWrapper {
  height: 319px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.profileWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.info {
  margin-right: 40px;
  margin-bottom: 19px;
}

.password {
  margin-top: 40px;
}

.submit {
  align-self: flex-end;
  margin-top: 19px;
  margin-bottom: 10px;
}
