.card {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  max-width: 272px;
  min-width: 100%;
  margin-bottom: 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  cursor: pointer;
}

.card:hover {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.portrait {
  height: 364px;
  width: 100%;
}

.landscape {
  max-height: 225px;
  min-height: 100px;
  height: 400px;
}

/*.card img {
	max-width: 100%;
}

.card video {
	max-width: 100%;
}
*/

.portrait img,
.portait video {
  max-height: 100%;
  /*min-width: 100%;*/
  /*z-index: 1;*/
}

.landscape img,
.landscape video {
  max-width: 100%;
  /*z-index: 1;*/
}

.videoWrapper {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  top: 0;
  flex-direction: row;
  align-self: center;
  justify-content: center;
}

.playBtnWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: relative;
  align-self: center;
  left: 0;
}

.videoWrapper video {
  /*	position: relative;
	top: -200%;*/
  position: absolute;
  max-width: 100%;
}

.playBtn {
  /*position: relative;*/
  /*flex-grow: 1;*/
  align-self: center;
  left: calc((100% - 32px) / 2);
  z-index: 10;
  height: 64px;
  width: 64px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 30px;
  background-color: rgba(254, 254, 254, 0.3);
}

.playBtn img {
  height: 44px;
  padding-left: 2px;
  fill: white;
}
